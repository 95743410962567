import React from "react";

// Redux
import { useSelector } from "react-redux";

//Icons
import checkIcon from "../../../assets/icons/check-icon.svg";

const PricingCardMobile = ({
  animationCounter,
  cardDatas,
  currencyList,
  priceData,
  selectedCurrency,
  selectedType,
  selectedItem,
  selectedItemIndex,
  setAnimationCounter,
  setSelectedCurrency,
  setSelectedType,
  otherClassName
}) => {
  // Language data
  const currentLanguage = useSelector((state) => state.changeLanguageReducer);

  return (
    <div id="pricing-mobile-table-section" className={`${otherClassName ? otherClassName : ''}`}>
      <div id="pricing-mobile-table-section-bottom">
        <div
          className="wow animate__fadeIn"
          data-wow-duration="2s"
          id="pricing-table"
        >
          <div id="pricing-table-title-area">
            {cardDatas.map((pricingData, index) => (
              <div
                key={index}
                onClick={() => pricingData.selectedEvent()}
                className={` pricing_table_title_container
                  ${
                    pricingData.key !== selectedItem
                      ? "pricing_table_title_container_unselected"
                      : selectedItemIndex === 0
                      ? "gold_background"
                      : "black_white_background"
                  }
                  ${
                    pricingData.key !== selectedItem &&
                    pricingData.title === "Enterprise"
                      ? "pricing_table_title_container_unselected_borderRight"
                      : "pricing_table_title_container_unselected_borderleft"
                  }
                  `}
              >
                <span
                  className={`${
                    pricingData.key === selectedItem
                      ? "pricing_table_selected_title"
                      : ""
                  }`}
                >
                  {pricingData?.title}
                </span>
              </div>
            ))}
          </div>

          <div id="pricing-table-mobile-body">
            <div className="pricing_card_price">
              <span className="pricing_card_price_emplyee_limitation">
                {selectedItemIndex === 0
                  ? priceData.upto?.[currentLanguage]
                  : priceData.morethan?.[currentLanguage]}
              </span>
              <div className="pricing_card_price_amount">
                <span>
                  <small>
                    {cardDatas[selectedItemIndex].price !== "-"
                      ? selectedCurrency.symbol
                      : priceData.contactUs?.[currentLanguage]}
                  </small>
                  {cardDatas[selectedItemIndex].price !== "-"
                    ? cardDatas[selectedItemIndex].price?.[selectedType]?.[
                        selectedCurrency.acronym
                      ]
                    : ""}
                </span>
              </div>
              {cardDatas[selectedItemIndex].key === "standart" ? (
                <div className="pricing_card_price_explanation">
                  <span>{priceData.perActiveUser?.[currentLanguage]}*</span>
                  <span>{priceData.vatIncluded?.[currentLanguage]}</span>
                </div>
              ) : null}
            </div>
            <ul>
              {cardDatas[selectedItemIndex].features?.map((item, index) => (
                <li key={index}>
                  <img src={ checkIcon } alt="check icon" /> {item}
                </li>
              ))}
            </ul>
          </div>
          <div id="pricing-table-mobile-footer">
            <button
              className="website_btn discover_btn"
              onClick={ () => cardDatas[selectedItemIndex].buttonEvent() }
            >
              {cardDatas[selectedItemIndex]?.buttonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingCardMobile;
