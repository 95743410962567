import React from 'react'

//Redux
import { useSelector } from 'react-redux'

//Icon
import checkIcon from '../../../assets/icons/check-icon.svg'

//Localized
import priceData from '../../../localized/priceLocalized.json'

const PricingCard = ({
  data,
  selectedType,
  selectedCurrency
}) => {
  const currentLanguage = useSelector(state => state.changeLanguageReducer)

  return (
    <div
      onClick={() => data.selectedEvent()}
      className={`pricing_card d-none d-sm-block`}
    >
      <div
        className={`pricing_card_title ${
          data.key === 'standart' ? 'gold_background' : 'black_white_background'
        }`}
      >
        <span>{data.title}</span>
      </div>
      <div className='pricing_card_price'>
        <span className='pricing_card_price_emplyee_limitation'>
          {data.title === 'Gold'
            ? priceData.upto?.[currentLanguage]
            : priceData.moreThan?.[currentLanguage]}
        </span>
        <div className='pricing_card_price_amount'>
          <span>
            <small>
              {data.price !== '-'
                ? selectedCurrency.symbol
                : `${priceData.custom?.[currentLanguage]}`}
            </small>
            {data.price !== '-'
              ? data.price?.[selectedType]?.[selectedCurrency.acronym]
              : ''}
          </span>
        </div>
        {data.key === 'standart' ? (
          <div className='pricing_card_price_explanation'>
            <span>{priceData.perActiveUser?.[currentLanguage]}*</span>
            <span>{priceData.vatIncluded?.[currentLanguage]}</span>
            <span>
              {selectedType === 'monthly'
                ? priceData.whenBilledMonthly?.[currentLanguage]
                : priceData.whenBilledYearly?.[currentLanguage]}
            </span>
          </div>
        ) : null}
      </div>
      <div className={`pricing_card_features ${data.key === "standart" ? "pricing_card_features_fit" : ""}`}>
        <ul>
          {data.features?.map((feature, index) => (
            <li key={index}>
              <img src={checkIcon} alt='check-icon' />
              {feature}
            </li>
          ))}
        </ul>
      </div>
      <div className="pricing_card_button_area" >
        { data.key === 'standart' &&
          <span className="muni_xxlarge_text mb-3 bold_text">
           {priceData.noCreditCardRequired?.[currentLanguage]}!
          </span>
        }
        <button
          onClick={() => data.buttonEvent()}
          // className='website_btn whiteOutline_btn'
          className={`${data.key === "standart" ? "website_btn signup_btn text non_styled_link text-white mb-4" 
          : "website_btn whiteOutline_btn"}`}
        >
          {data.buttonText}
        </button>
      </div>
    </div>
  )
}

export default PricingCard
