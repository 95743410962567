import React, { useState, useEffect } from "react"

//React router
import { navigate } from "gatsby"

//Redux
import { useSelector } from "react-redux"

//Component
import Section from "../../components/Tools/Section/Section"
import PricingCard from "../../components/Tools/PricingCard/PricingCard"
import SliderButton from "../../components/Tools/SliderButton/SliderButton"
import PricingCardMobile from "../../components/Tools/PricingCard/PricingCardMobile"
import CommunicationBar from "../../components/Tools/CommunicationBar/CommunicationBar"
import PricingCompareTable from "../../components/Tools/PricingCompareTable/PricingCompareTable"

//Localized
import priceData from "../../localized/priceLocalized.json"
import buttonData from "../../localized/buttonsLocalized.json"
import communicationBarData from "../../localized/communicationBarLocalized.json"
import Layout from "../../components/Layout"

// Illustration
import cheaperIllustrationTr from "../../assets/illustration/cheaper-illustration-tr.svg"
import cheaperIllustrationEn from "../../assets/illustration/cheaper-illustration-en.svg"

const Pricing = () => {
  //Language data
  const currentLanguage = useSelector(state => state.changeLanguageReducer)

  //Get screen sizes
  const [windowSize, setWindowSize] = useState(
    typeof window !== "undefined" ? window.innerWidth : ""
  )

  const [selectedItem, setSelectedItem] = useState("standart")

  //Communication bar variables
  const [contactEmail, setContactEmail] = useState("")

  /*  
    If the selection is gold, then index is 0.
    If it is enterprise, then index is 1.
  */
  const [selectedItemIndex, setSelectedItemIndex] = useState(0)

  //Sliderin animasyon statusunu yonetmek icin kullanilacak
  const [animationCounter, setAnimationCounter] = useState(0)

  //Aylik veya yillik secimlerinin tutulacagi degisken
  const [selectedType, setSelectedType] = useState("yearly")

  //Currency list and selected currency
  const [selectedCurrency, setSelectedCurrency] = useState({
    acronym: "TRY",
    symbol: "₺",
  })

  const currencyList = [
    { acronym: "TRY", symbol: "₺" },
    { acronym: "USD", symbol: "$" },
    { acronym: "EUR", symbol: "€" },
    { acronym: "GBP", symbol: "£" },
  ]

  let cardDatas = [
    // Gold plan data
    {
      title: priceData.gold?.[currentLanguage],
      price: {
        monthly: {
          TRY: "50",
          USD: "6",
          EUR: "5",
          GBP: "5",
        },
        yearly: {
          TRY: "40",
          USD: "5",
          EUR: "4",
          GBP: "4",
        },
      },
      features: [
        priceData.unlimitedExpenses?.[currentLanguage],
        priceData.unlimitedExpenseReport?.[currentLanguage],
        priceData.unlimitedDocumentScans?.[currentLanguage],
        priceData.customApprovalFlow?.[currentLanguage],
        priceData.customLimits?.[currentLanguage],
        priceData.integratedMessaging?.[currentLanguage],
        // priceData.differentUserTypes?.[currentLanguage]
      ],
      selectedEvent: () => {
        setSelectedItem("standart")
        setSelectedItemIndex(0)
      },
      buttonEvent: () => {
        navigate(`https://app.muni.com.tr/signup`)
        window.scrollTo(0, 0)
      },
      key: "standart",
      buttonText: buttonData.signUp?.[currentLanguage],
    },
    // Enterprise Data
    {
      title: priceData.enterprise?.[currentLanguage],
      price: "-",
      features: [
        priceData.everythingInGold?.[currentLanguage],
        priceData.premiumSupport?.[currentLanguage],
        priceData.projectManager?.[currentLanguage],
        priceData.customIntegrations?.[currentLanguage],
      ],
      selectedEvent: () => {
        setSelectedItem("enterprise")
        setSelectedItemIndex(1)
      },
      buttonEvent: () => {
        navigate(`/company/contact-us/?lang=${currentLanguage}`, {
          state: {
            requestEmail: "",
            subjectType: priceData.enterpriseQuote?.[currentLanguage],
          },
        })
        window.scrollTo(0, 0)
      },
      key: "enterprise",
      buttonText: buttonData.getQuote?.[currentLanguage],
    },
  ]

  const tableDatas = [
    {
      title: priceData.item1?.[currentLanguage],
      inGold: true,
      inEnterprise: true,
    },
    {
      title: priceData.item2?.[currentLanguage],
      inGold: true,
      inEnterprise: true,
    },
    {
      title: priceData.item3?.[currentLanguage],
      inGold: true,
      inEnterprise: true,
    },
    {
      title: priceData.item4?.[currentLanguage],
      inGold: true,
      inEnterprise: true,
    },
    {
      title: priceData.item5?.[currentLanguage],
      inGold: true,
      inEnterprise: true,
    },
    {
      title: priceData.item6?.[currentLanguage],
      inGold: true,
      inEnterprise: true,
    },
    {
      title: priceData.item7?.[currentLanguage],
      inGold: true,
      inEnterprise: true,
    },
    {
      title: priceData.item8?.[currentLanguage],
      inGold: true,
      inEnterprise: true,
    },
    {
      title: priceData.item9?.[currentLanguage],
      inGold: true,
      inEnterprise: true,
    },
    {
      title: priceData.item10?.[currentLanguage],
      inGold: true,
      inEnterprise: true,
    },
    {
      title: priceData.item11?.[currentLanguage],
      inGold: false,
      inEnterprise: true,
    },
    {
      title: priceData.item12?.[currentLanguage],
      inGold: false,
      inEnterprise: true,
    },
    {
      title: priceData.item13?.[currentLanguage],
      inGold: false,
      inEnterprise: true,
    },
  ]

  const pricingCardSection = (
    <div className="pricing_container">
      <span
        className="pricing_container_header wow animate__fadeInDown"
        data-wow-duration="1s"
      >
        {priceData.transparentFlexible?.[currentLanguage]}
      </span>
      <span
        className="pricing_container_subheader wow animate__fadeInDown"
        data-wow-duration="2s"
      >
        {priceData.payUse?.[currentLanguage]}
      </span>
      <div
        className="select_membership_type wow animate__fadeIn"
        data-wow-duration="2s"
      >
        <div className="select_membership_type_currency">
          <span className="muni_label">
            {priceData.currency?.[currentLanguage]}
          </span>
          <select
            value={JSON.stringify(selectedCurrency)}
            onChange={e => setSelectedCurrency(JSON.parse(e.target.value))}
            className="muni_select_box w-100"
            onFocus={e => {
              e.target.classList.add("active_input_parent")
            }}
            onBlur={e => {
              e.target.classList.remove("active_input_parent")
            }}
          >
            {currencyList.map((item, index) => (
              <option key={index} value={JSON.stringify(item)}>
                {item.acronym}
              </option>
            ))}
          </select>
        </div>
        <div className="select_membership_type_period">
          <span className="muni_label">
            {priceData.membershipType?.[currentLanguage]}
          </span>
          <div className="select_membership_type_period_label">
            <SliderButton
              setAnimationCounter={setAnimationCounter}
              setSelectedType={setSelectedType}
              animationCounter={animationCounter}
              selectedType={selectedType}
            />
            <img
              className={
                currentLanguage === "tr"
                  ? "select_membership_type_period_label_text_tr"
                  : "select_membership_type_period_label_text"
              }
              width={currentLanguage === "tr" ? "150px" : "100px"}
              height="auto"
              src={
                currentLanguage === "tr"
                  ? cheaperIllustrationTr
                  : cheaperIllustrationEn
              }
              alt="Cheaper ilustration"
            />
          </div>
        </div>
      </div>
      <div className="pricing_container_card_area d-none d-md-flex">
        {cardDatas.map((cardData, index) => (
          <PricingCard
            key={index}
            selectedItem={selectedItem}
            data={cardData}
            selectedType={selectedType}
            selectedCurrency={selectedCurrency}
          />
        ))}
      </div>
      <PricingCardMobile
        animationCounter={animationCounter}
        cardDatas={cardDatas}
        currencyList={currencyList}
        priceData={priceData}
        selectedCurrency={selectedCurrency}
        selectedItem={selectedItem}
        selectedItemIndex={selectedItemIndex}
        selectedType={selectedType}
        setAnimationCounter={setAnimationCounter}
        setSelectedCurrency={setSelectedCurrency}
        setSelectedType={setSelectedType}
        otherClassName="d-flex d-md-none"
      />
    </div>
  )

  const thirdSection = (
    <div id="pricing-third-section">
      {/* <div className="section_paragraph wow animate__fadeInLeft" data-wow-duration="2s" id='pricing-third-section-paragraph'>
            <span className='section_paragraph_middle'>
              <u>{`${priceData.thirdSectionTitle?.[currentLanguage]} ?`}</u>
            </span>
            <div className="section_paragraph_bottom wow animate__fadeInLeft" data-wow-duration='2s'>
              <span className="muni_xxlarge_text">
                {priceData.thirdSectionParagrafOne?.[currentLanguage]}
              </span>
              <span className="muni_xxlarge_text">
                {priceData.thirdSectionParagrafTwo?.[currentLanguage]}
              </span>
              <span className="muni_xxlarge_text">
                {priceData.thirdSectionParagrafThree?.[currentLanguage]}
              </span>
            </div>
      </div> */}
      <div
        className="communication_bar_container wow animate__fadeIn"
        data-wow-duration="2s"
      >
        <div className="communication_bar_header">
          {`${communicationBarData.readyToStart?.[currentLanguage]} ?`}
        </div>
        <CommunicationBar
          id="pricing-communication-bar"
          inputValue={contactEmail}
          changeInputValue={setContactEmail}
          navigateLink={`https://app.muni.com.tr/signup/?snemail=${contactEmail}`}
          blankPage={true}
          type="signUp"
          white={false}
        />
      </div>
    </div>
  )

  // Use effects
  useEffect(() => {
    function handleResize() {
      setWindowSize(window.innerWidth)
    }
    function handleOrientationChange() {
      setWindowSize(window.innerWidth)
    }
    window.addEventListener("resize", handleResize)
    window.addEventListener("orientationchange", handleOrientationChange)

    return () => {
      window.removeEventListener("resize", handleResize)
      window.removeEventListener("orientationchange", handleOrientationChange)
    }
  }, [windowSize])

  return (
    <Layout>
      <div id="pricing">
        <Section
          sectionId="pricing_top"
          backgroundColor="white"
          sectionContent={pricingCardSection}
        />
        {/* <Section
        otherClassName="d-block d-md-none"
        backgroundColor="white"
        sectionContent={
          <PricingCardMobile
            animationCounter={animationCounter}
            cardDatas={cardDatas}
            currencyList={currencyList}
            priceData={priceData}
            selectedCurrency={selectedCurrency}
            selectedItem={selectedItem}
            selectedItemIndex={selectedItemIndex}
            selectedType={selectedType}
            setAnimationCounter={setAnimationCounter}
            setSelectedCurrency={setSelectedCurrency}
            setSelectedType={setSelectedType}
          />
        }
      /> */}
        <Section
          backgroundColor="white"
          sectionContent={
            <PricingCompareTable
              selectedItem={selectedItem}
              windowSize={windowSize}
              priceData={priceData}
              tableDatas={tableDatas}
            />
          }
        />
        <Section
          backgroundColor="white"
          sectionContent={thirdSection}
          sectionId="pricing-third-section-container"
        />
      </div>
    </Layout>
  )
}

export default Pricing
