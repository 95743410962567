import React from "react";

//Redux
import { useSelector } from "react-redux";

//Icons
import checkIcon from "../../../assets/icons/check-icon.svg";

const PricingCompareTable = ({
  selectedItem,
  windowSize,
  priceData,
  tableDatas,
}) => {
  //Language data
  const currentLanguage = useSelector((state) => state.changeLanguageReducer);

  return (
    <div
      className="pricing_comparison_table_area wow animate__fadeIn"
      data-wow-duration="2s"
    >
      <div className="pricing_comparison_table_container">
        <table>
          <thead>
            <tr>
              <th
                className={`${
                  selectedItem === "standart" && windowSize <= 768
                    ? "table_header_gold_background"
                    : selectedItem === "standart" && windowSize > 768
                    ? ""
                    : "table_header_black_white"
                }`}
              ></th>
              <th
                className={`${
                  selectedItem === "standart" && windowSize <= 768
                    ? "table_header_gold_background"
                    : windowSize >= 768
                    ? "table_header_gold_background"
                    : "d-none"
                }`}
              >
                {priceData.gold?.[currentLanguage]}
              </th>
              <th
                className={`${
                  selectedItem === "enterprise" && windowSize <= 768
                    ? "table_header_black_white"
                    : windowSize >= 768
                    ? "table_header_black_white"
                    : "d-none"
                }`}
              >
                {priceData.enterprise?.[currentLanguage]}
              </th>
            </tr>
          </thead>
          <tbody>
            {tableDatas.map((tableData, index) => (
              <tr key={index}>
                <td>{tableData.title}</td>
                <td
                  className={`${
                    selectedItem === "standart" && windowSize <= 768
                      ? ""
                      : windowSize >= 768
                      ? ""
                      : "d-none"
                  }`}
                >
                  {tableData.inGold ? (
                    <img src={checkIcon} alt="check-icon" />
                  ) : null}
                </td>
                <td
                  className={`${
                    selectedItem === "enterprise" && windowSize <= 768
                      ? ""
                      : windowSize >= 768
                      ? ""
                      : "d-none"
                  }`}
                >
                  {tableData.inEnterprise ? (
                    <img src={checkIcon} alt="check-icon" />
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PricingCompareTable;
