import React from "react";

//Redux
import {useSelector} from 'react-redux'

//Localized
import sliderData from '../../../localized/priceLocalized.json'

const SliderButton = ({
  setAnimationCounter,
  setSelectedType,
  animationCounter,
  selectedType,
}) => {
  //Language data
  const currentLanguage = useSelector(state => state.changeLanguageReducer)

  return (
      <div className="monthly_or_yearly_slider">
        <div
          onClick={() => {
            setAnimationCounter(1);
            setSelectedType("monthly");
          }}
          className={`${
            selectedType === "monthly" ? "selected_type_features" : ""
          } monthly_or_yearly_slider_left`}
        >
          <span>{sliderData.monthly?.[currentLanguage]}</span>
        </div>
        <div
          onClick={() => {
            setAnimationCounter(1);
            setSelectedType("yearly");
          }}
          className={`${
            selectedType === "yearly" ? "selected_type_features" : ""
          } monthly_or_yearly_slider_right`}
        >
          <span>{sliderData.annualy?.[currentLanguage]}</span>
        </div>
        <div
          className={`${
            selectedType === "monthly" && animationCounter !== 0
              ? "selected_type_container_left"
              : selectedType === "yearly" && animationCounter !== 0
              ? "selected_type_container_right"
              : ""
          } selected_type_container`}
        ></div>
      </div>
  );
};

export default SliderButton;
